.Login
    margin: 0 auto
    margin-top: 70px
    max-width: 400px
    padding: 40px 40px
    background: #fff

.Button 
  margin: auto
  display: block
