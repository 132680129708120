
td {
    text-align: center; 
    vertical-align: middle;
}
th {
    text-align: center; 
    vertical-align: middle;
}


