.sort-arrow-container {
    display: flex;
    align-items: flex-end;
  }
  
  .sort-arrow {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 5px;
    vertical-align: middle;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    transition: all 0.3s;
  }
  
  .sort-arrow.active.up {
    border-bottom: 8px solid #000;
  }
  
  .sort-arrow.active.down {
    border-top: 8px solid #000;
  }
  