.modal-dialog {
  min-width: fit-content;
}

.modal-dialog.dealerpriceupdatetable {
  min-width: 100%;
  min-height: 50%;
}

.table-responsive {
  overflow-x: auto;
  white-space: nowrap;
}

.modal-body {
  overflow-x: auto;
}

.modal-open .modal {
  overflow-x: visible;
}
