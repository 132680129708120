.main-content-class{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.navigation-bar {
    display: flex;
}

.inventoryTableDetails {
    display: flex;
    padding: 5px;
    width: 100%;
    margin-top: 10px;
}

.navigational-data {
    display: flex;
    width: 100%;
}
