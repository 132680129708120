.mantine-Group-root {
  //! Fix for mantine Radio group align radio buttons to center
  justify-content: center !important;
}

.react-datepicker {
  z-index: 1;
}
.mantine-SegmentedControl-root {
  z-index: 0;
}
.imagePreviewContainer {
  position: relative;
}

.removeImageButton {
  position: absolute;
  cursor: pointer;
  right: -30px;
  top: 0%;
  font-size: large;
  height: 40px;
  width: 40px;
}
.mantine-DatePicker-calendarHeader {
  & > :last-child > svg {
    transform: rotate(180deg) !important;
  }
}

.offerMasterMainContainer {
  background-color: rgba(255, 219, 14, 0.349);
}

.offerListingMainContainer {
  background-color: rgba(255, 219, 14, 0.349);
}

.offerMasterFormTitle {
  text-align: center;
  font-size: 25px;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 5px;
}

.offerMasterFormFieldsHeadingMain {
  padding-bottom: 20px;
  color: blue;
  font-size: 20px;
  font-weight: 800;
  text-transform: uppercase;
}
.offerMasterFormRetailerSeriesBtn {
  border-radius: 5px;
  background-color: blue;
  margin-top: 10px;
  margin-bottom: 30px;
}

.offerMasterFormSubmitBtn {
  border-radius: 5px;
  width: 300px;
  background-color: green;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 40px;

  &:hover {
    background-color: rgb(2, 187, 2);
  }

  &:disabled {
    background-color: gray;
  }
}

.required {
  &::after {
    content: " *";
    color: red;
    font-size: 15px;
    line-height: 0;
  }
}

.OfferEditMode {
  &__ReturnText {
    margin-top: 10px;
    color: royalblue;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.OfferListingCard {
  &__Container {
    box-shadow: 1px 2px 20px rgba(8, 0, 0, 0.589);
    width: 80vw;
    margin: auto;
    border-radius: 10px;
    // height: 285px;
  }

  &__Main_SubHeading {
    font-weight: 700;
  }

  &__Table {
    border: solid 1px gray;
  }

  &__Header {
    margin: 0;

    &__Col {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      padding: 10px;

      &__DeleteIcon {
        width: 35px;
        margin-left: 20px;
        &:hover {
          cursor: pointer;
        }
      }
      &__EditIcon {
        width: 43px;
        margin-left: auto;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.underline {
  text-decoration: underline;
}

.rdt {
  width: 400px;
}
